import axios from "axios"

export const state = {
  params: null,
}

export const getters = {
  params: (state) => {
    return _.get(state, "params")
  },
}

export const actions = {
  reset: ({ commit, dispatch }) => {
    dispatch("app/setActiveDrawer", null, { root: true })
    commit("RESET_STATE")
  },
  setParams: ({ commit, dispatch }, params) => {
    if (params) {
      dispatch("app/setActiveDialog", { dialog: "activeModal" }, { root: true })
    }
    commit("SET_PARAMS", params)
    dispatch("app/setActiveDrawer", "FieldDrawerWrapper", { root: true })
  },
  loadFieldStats: ({ commit, dispatch, getters, rootGetters }, params) => {
    // localhost:3000/api/v1/snowflakes/field_stats?group_id=1&field_hid=160367268
    return new Promise((resolve, reject) => {
      axios
        .get(
          `snowflakes/field_stats?group_id=${params.group_id}&field_hid=${params.field_hid}`
        )
        .then((resp) => {
          resolve(_.get(resp, "data"))
        })
        .catch((e) => {
          console.error("error", e)
          reject(e)
        })
    })
  },
}

export const mutations = {
  SET_PARAMS(state, params) {
    state.params = params
  },
  RESET_STATE(state) {
    state.params = null
  },
}
