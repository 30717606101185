import {
  BaseState,
  BaseActions,
  BaseMutations,
  BaseGetters,
} from "@utils/mixins/store"
import axios from "axios"
import RouteHelpers from "@mixins/route-helpers"
import { DataSources } from "@/src/utils/mixins/data-sources"
import { FieldTypes } from "@mixins/fields/types"
import {
  flatfileUseCases,
  addCompaniesConfig,
  addUsersConfig,
  mapCompanyFields,
  userFields,
} from "@/src/utils/mixins/flatfile-helpers"
import SearchMixin from "@mixins/search"

export const state = {
  ...BaseState,
  license: "eadec29b-ea00-4123-aa69-25459cb89e60",
  companyImports: null,
  saving: false,
}

export const getters = {
  ...BaseGetters,
  license: (state) => {
    return _.get(state, "license")
  },
  companyImports: (state) => {
    return _.get(state, "companyImports")
  },
  saving: (state) => {
    return _.get(state, "saving")
  },
  //should pass in sithOption.type from flatfile.vue
  config: (state, rootState, rootGetters) => (useCase) => {
    return {
      // This determines whether we are importing companies or users
      ...activeConfiguration(useCase),
      // Companies shcema is generated dynamically, user schema is set statically
      fields:
        useCase === flatfileUseCases.USERS
          ? userFields
          : groupFieldData(rootGetters),
    }
  },
  flatFileFields: (state, rootState, rootGetters) => {
    return _.filter(_.get(rootGetters, "fields.all", []), (field) => {
      return (
        (field.source === DataSources.GROUP &&
          ![FieldTypes.FILE, FieldTypes.GROUP].includes(field.use_case)) ||
        (field.source === DataSources.KITE &&
          ["kt_name", "kt_url"].includes(field.attribute))
      )
    })
  },
}

export const actions = {
  ...BaseActions,
  importCompanyData: ({ commit, dispatch, getters }, payload) => {
    if (getters.saving) {
      return
    }

    commit("SET_SAVING", true)
    commit("SET_LOADING", true)

    return new Promise((resolve, reject) => {
      axios
        .post("snowflakes/flatfile_company_import", payload)
        .then((result) => {
          const connection = _.get(result, "data.meta.connection_object")
          if (connection) {
            dispatch("connections/pushNewConnection", connection, {
              root: true,
            })
          }
          commit("INCREMENT_RELOAD_KEY")
          resolve(result)
        })
        .catch((e) => {
          console.error(e)
          reject(e)
          commit("SET_SAVING", false)
        })
        .finally(() => {
          commit("SET_SAVING", false)
        })
    })
  },
  importUserData: ({ commit }, payload) => {
    commit("SET_LOADING", true)

    return new Promise((resolve, reject) => {
      axios
        .post("snowflakes/flatfile_user_import", payload)
        .then((result) => {
          commit("INCREMENT_RELOAD_KEY")
          resolve(result)
        })
        .catch((e) => {
          console.error(e)
          reject(e)
        })
    })
  },
  loadCompanyImports: ({ commit, dispatch, getters, rootGetters }, payload) => {
    return new Promise((resolve, reject) => {
      const searchObj = SearchMixin.buildSearchObject({
        admin: false,
        indices: ["import_records"],
        includes: [
          "formatted_filename",
          "formatted_status",
          "context_data",
          "actions",
          "external_id",
          "use_case",
          "id",
        ],
        filters: [
          {
            attr: "use_case",
            type: "term",
            value: "flatfile_company_import",
            verb: "must",
          },
          {
            attr: "access_policy",
            type: "terms",
            value: SearchMixin.buildAccessPolicyWords({
              groups: [
                { id: rootGetters["auth/activeGroup"].id, action: "write" },
              ],
            }),
          },
        ],
        keywords: payload.q
          ? SearchMixin.keywordsObject(payload.q, [
              { filter: "import_records" },
            ])
          : null,
        page: 1,
        per_page: 50,
      })

      commit("SET_COMPANY_IMPORTS", [])

      axios
        .get("search", { params: { q: searchObj } })
        .then((resp) => {
          const results = _.get(resp, "data.results", [])
          commit("SET_COMPANY_IMPORTS", results)
          resolve(results)
        })
        .catch((e) => {
          reject(e)
        })
    })
  },
  downloadFailedDataCSV: ({ commit }, importRecord) => {
    const filePath = `${axios.defaults.baseURL}/snowflakes/flatfile_xlsx?import_record_id=${importRecord.id}`
    RouteHelpers.openInNewTab(filePath)
  },
  viewCompanies: ({ dispatch, commit, getters, rootGetters }, payload) => {
    dispatch(
      "connections/transitionFromWord",
      { word: `ir${_.get(payload, "id")}` },
      {
        root: true,
      }
    )
  },
}

export const mutations = {
  ...BaseMutations,
  SET_COMPANY_IMPORTS(state, importsCache) {
    state.companyImports = importsCache
  },
  SET_SAVING(state, boolean) {
    state.saving = boolean
  },
}

const groupFieldData = (rootGetters) => {
  // Filter down to proprietary fields, excluding certain types and Nucla name and url fields
  const groupFields = _.filter(
    _.get(rootGetters, "fields.all", []),
    (field) => {
      return (
        (field.source === DataSources.GROUP &&
          ![FieldTypes.FILE, FieldTypes.GROUP].includes(field.use_case)) ||
        (field.source === DataSources.KITE &&
          ["kt_name", "kt_url"].includes(field.attribute))
      )
    }
  )
  const users = _.get(rootGetters, "users.all", [])
  return mapCompanyFields({ groupFields, users })
}

function activeConfiguration(useCase) {
  switch (useCase) {
    case flatfileUseCases.COMPANIES:
      return addCompaniesConfig
    case flatfileUseCases.USERS:
      return addUsersConfig
    default:
      //should I console log some error message here if there is no usecase passed in?
      return null
  }
}
