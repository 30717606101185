export const state = {
  munchies: null,
}

export const getters = {
  munchies(state) {
    return _.get(state, "munchies")
  },
}

export const actions = {
  setMunchies({ commit }, munchies) {
    commit("SET_MUNCHIES", munchies)
  },
}

export const mutations = {
  SET_MUNCHIES(state, munchies) {
    state.munchies = munchies
  },
}
