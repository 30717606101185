// Register each file as a corresponding Vuex module. Module nesting
// will mirror [sub-]directory hierarchy and modules are namespaced
// as the camelCase equivalent of their file name.

import camelCase from "lodash/camelCase"

const moduleCache = {}
const root = { modules: {} }
;(function updateModules() {
  const requireModule = require.context(
    ".",
    true,
    /^((?!index|\.unit\.).)*\.js$/
  )

  requireModule.keys().forEach((fileName) => {
    const moduleDefinition = requireModule(fileName)
    if (moduleCache[fileName] === moduleDefinition) {
      return
    }

    moduleCache[fileName] = moduleDefinition

    const modulePath = fileName
      .replace(/^\.\//, "")
      .replace(/\.\w+$/, "")
      .split(/\//)
      .map(camelCase)

    const { modules } = getNamespace(root, modulePath)

    modules[modulePath.pop()] = {
      namespaced: true,
      ...moduleDefinition,
    }
  })

  // If the environment supports hot reloading...
  if (module.hot) {
    module.hot.accept(requireModule.id, () => {
      updateModules()
      require("../store").default.hotUpdate({ modules: root.modules })
    })
  }
})()

// Recursively get the namespace of a Vuex module, even if nested.
function getNamespace(subtree, path) {
  if (path.length === 1) {
    return subtree
  }

  const namespace = path.shift()
  subtree.modules[namespace] = {
    modules: {},
    ...subtree.modules[namespace],
  }
  return getNamespace(subtree.modules[namespace], path)
}

export default root.modules
