import {
  BaseState,
  BaseActions,
  BaseMutations,
  BaseGetters,
} from "@utils/mixins/store"

import { genBaseResponse, parseResponses } from "@mixins/fields/group"
import { FieldTypes } from "@mixins/fields/types"
import StaticHelper from "@mixins/static-helpers"
import Definitions from "@mixins/fields/definitions"
import axios from "axios"
import Vue from "vue"

export const state = {
  ...BaseState,
  fieldGroupsCache: [],
  fieldGroupCache: null,
  fieldGroupFieldCache: null,
  responsesCache: null,
  responseCache: null,
  isAddingFieldGroupField: false,
  reloadFieldGroupDataKey: 0,
}

export const getters = {
  ...BaseGetters,
  fieldGroupsCache: (state) => {
    return state.fieldGroupsCache
  },
  fieldGroupCache: (state) => {
    return state.fieldGroupCache
  },
  fieldGroupFieldCache: (state) => {
    return state.fieldGroupFieldCache
  },
  responsesCache: (state) => {
    return state.responsesCache
  },
  responseCache: (state) => {
    return state.responseCache
  },
  // ---
  isAddingFieldGroupField: (state) => {
    return state.isAddingFieldGroupField
  },
  reloadFieldGroupDataKey: (state) => {
    return state.reloadFieldGroupDataKey
  },
}

export const actions = {
  ...BaseActions,

  // ---
  // Field Group
  // ---

  saveFieldGroupFieldDatum: ({ dispatch, commit, state, rootGetters }) => {
    const isCreating = !_.get(state.fieldGroupCache, "field_datum.id")

    if (isCreating) {
      const params = {
        use_case: FieldTypes.GROUP,
        user_id: rootGetters["auth/activeUser"].id,
        field_id: state.fieldGroupCache.id,
        group_id: state.fieldGroupCache.group_id,
        group_company_id: state.fieldGroupCache.field_datum.group_company_id,
        data: state.fieldGroupCache.field_datum.data,
      }

      return new Promise((resolve, reject) => {
        axios
          .post("/field_data", params)
          .then((response) => {
            commit("SET_FIELD_GROUP_FIELD_DATUM", response.data.field_datum)
            commit("UPDATE_FIELD_GROUPS_CACHE")
            commit("RELOAD_FIELD_GROUP_DATA_KEY")

            dispatch(
              "fields/cacheFieldDatumInGCF",
              {
                fieldDatum: response.data.field_datum,
              },
              {
                root: true,
              }
            )

            resolve(response.data.field_datum)
          })
          .catch((error) => {
            console.error(error)
            reject(error)
          })
      })
    } else {
      return new Promise((resolve, reject) => {
        axios
          .put(`/field_data/${state.fieldGroupCache.field_datum.id}`, {
            user_id: rootGetters["auth/activeUser"].id,
            data: state.fieldGroupCache.field_datum.data,
          })
          .then((response) => {
            commit("RELOAD_FIELD_GROUP_DATA_KEY")
            commit("SET_FIELD_GROUP_FIELD_DATUM", response.data.field_datum)
            commit("UPDATE_FIELD_GROUPS_CACHE")

            dispatch(
              "fields/cacheFieldDatumInGCF",
              {
                fieldDatum: response.data.field_datum,
              },
              {
                root: true,
              }
            )

            resolve(response.data.field_datum)
          })
          .catch((error) => {
            console.error(error)
            reject(error)
          })
      })
    }
  },

  setFieldGroupCache: ({ commit }, params) => {
    commit("SET_FIELD_GROUP_CACHE", _.cloneDeep(params.field_group))
    commit("UPDATE_FIELD_GROUPS_CACHE")
  },
  // Helper to parse and instantiate Group "Field Group" Caches,
  // required for Add Response and Field Group edit flows.
  setFieldGroupsResponseCache({ commit }, response) {
    const taxonomiesCache = _.get(response, "data.taxonomies", [])

    let fieldGroupsCache = _.filter(_.get(response, "data.fields", []), {
      use_case: FieldTypes.GROUP,
    })

    if (taxonomiesCache.length > 0) {
      for (let i = 0; i < taxonomiesCache.length; i++) {
        const filteredSubfields = _.filter(
          _.get(taxonomiesCache, `${[i]}.fields`) || [],
          {
            use_case: FieldTypes.GROUP,
          }
        )

        fieldGroupsCache = fieldGroupsCache.concat(filteredSubfields)
      }
    }

    if (!_.isEmpty(fieldGroupsCache)) {
      commit("SET_FIELD_GROUPS_CACHE", fieldGroupsCache)
    }
  },

  // Due to the change in how data is loaded with the Contextual Data Card, we've
  // built this new function to help control the cache state of field groups.
  addToFieldGroupsCache({ commit }, response) {
    // From the response, filter for field groups
    const fieldGroupsCache = _.filter(_.get(response, "data.fields", []), {
      use_case: FieldTypes.GROUP,
    })

    // Since card data is loaded asynchronously, we will push each field into the
    // fieldGroupsCache. Otherwise some date may be lost due to asynchronous concatinations
    if (!_.isEmpty(fieldGroupsCache)) {
      for (var i = 0; i < fieldGroupsCache.length; i++) {
        commit("PUSH_FIELD_GROUPS_CACHE", fieldGroupsCache[i])
      }
    }
  },
  setFieldGroupsCache: ({ commit }, cache) => {
    commit("SET_FIELD_GROUPS_CACHE", cache)
  },
  updateFieldGroupCache: ({ commit }, params) => {
    commit("UPDATE_FIELD_GROUP_CACHE", params)
    commit("UPDATE_FIELD_GROUPS_CACHE")
  },
  setFieldGroupFields: ({ commit }, fDefs) => {
    commit("SET_FIELD_GROUP_FIELDS", fDefs)
    commit("UPDATE_FIELD_GROUPS_CACHE")
  },

  // ----
  // Field Group Fields
  // ---

  addFieldGroupField: ({ commit }, params) => {
    commit("ADD_FIELD_GROUP_FIELD", params.field)
    commit("UPDATE_FIELD_GROUPS_CACHE")
  },
  updateFieldGroupField: ({ commit }, params) => {
    commit("UPDATE_FIELD_GROUP_FIELD", params.field)
    commit("UPDATE_FIELD_GROUPS_CACHE")
  },
  removeFieldGroupField: ({ commit }, params) => {
    commit("REMOVE_FIELD_GROUP_FIELD", params.field)
    commit("UPDATE_FIELD_GROUPS_CACHE")
  },
  setFieldGroupFieldCache: ({ commit }, field) => {
    commit("SET_FIELD_GROUP_FIELD_CACHE", field)
  },
  setIsAddingFieldGroupField: ({ commit }, isAdding) => {
    commit("SET_IS_ADDING_FIELD_GROUP_FIELD", isAdding)
  },

  // ---
  // Field Group Responses
  // ---

  parseResponses: ({ commit }, fGroup) => {
    commit("SET_FIELD_GROUP_CACHE", fGroup)
    commit("SET_RESPONSES_CACHE", {
      responses: parseResponses(fGroup),
      field_group_id: _.get(fGroup, "id"),
    })
  },
  setResponseCache: ({ commit }, params) => {
    commit("SET_RESPONSE_CACHE", params)
  },
  deleteResponse: ({ dispatch, commit }, params) => {
    commit("SET_RESPONSES_CACHE", params)
    dispatch("saveFieldGroupFieldDatum")
  },
  updateResponseOrder: ({ commit, dispatch }, params) => {
    commit("SET_RESPONSES_CACHE", params)
    dispatch("saveFieldGroupFieldDatum")
  },
  saveResponse: ({ dispatch, commit }, params) => {
    commit("SET_RESPONSE_CACHE", params)
    commit("SET_RESPONSE_ON_FIELD_GROUP_RESPONSES")
    return new Promise((resolve, reject) => {
      dispatch("saveFieldGroupFieldDatum")
        .then((fieldDatum) => {
          commit("RESET_RESPONSE_CACHE")
          resolve(fieldDatum)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },
  // Field Group Responses - Bulk Edit
  setResponseCacheForBulkEdit: ({ commit }, params) => {
    commit("SET_RESPONSE_CACHE_FOR_BULK_EDIT", params)
  },
  setResponseCacheOnFieldGroupForBulkEdit: ({ commit, dispatch, state }) => {
    commit("SET_RESPONSE_ON_FIELD_GROUP_RESPONSES")
    commit("UPDATE_FIELD_GROUPS_CACHE_FOR_BULK_EDIT")
    dispatch("fields/addFieldToBulkEditCache", state.fieldGroupCache, {
      root: true,
    })
  },
  setResponseForBulkEdit: ({ commit, dispatch, state }, params) => {
    commit("SET_RESPONSE_CACHE_FOR_BULK_EDIT", params)
    commit("SET_RESPONSE_ON_FIELD_GROUP_RESPONSES")
    commit("UPDATE_FIELD_GROUPS_CACHE_FOR_BULK_EDIT")
    dispatch("fields/addFieldToBulkEditCache", state.fieldGroupCache, {
      root: true,
    })
  },
  updateResponseOrderForBulkEdit: ({ commit, dispatch }, params) => {
    commit("SET_RESPONSES_CACHE", params)
    commit("UPDATE_FIELD_GROUPS_CACHE_FOR_BULK_EDIT")
    dispatch("fields/addFieldToBulkEditCache", state.fieldGroupCache, {
      root: true,
    })
  },
  deleteResponseForBulkEdit: ({ dispatch, commit }, params) => {
    commit("SET_RESPONSES_CACHE", params)
    commit("DELETE_ATTACHMENTS_FOR_RESPONSE", params)
    commit("UPDATE_FIELD_GROUPS_CACHE_FOR_BULK_EDIT")
    dispatch("fields/addFieldToBulkEditCache", state.fieldGroupCache, {
      root: true,
    })
  },
  // ---
  // Helpers
  // ---
  resetCaches: ({ commit }) => {
    commit("RESET_CACHES")
  },
  reloadFieldGroupDataKey: ({ commit }) => {
    commit("RELOAD_FIELD_GROUP_DATA_KEY")
  },
}

export const mutations = {
  ...BaseMutations,

  // ---
  // Field Group
  // ---

  UPDATE_FIELD_GROUPS_CACHE: (state) => {
    state.fieldGroupsCache = _.map(state.fieldGroupsCache, (fG) => {
      return fG.id === _.get(state, "fieldGroupCache.id", null)
        ? state.fieldGroupCache
        : fG
    })
  },
  UPDATE_FIELD_GROUPS_CACHE_FOR_BULK_EDIT: (state) => {
    state.fieldGroupsCache = _.map(state.fieldGroupsCache, (fG) => {
      return fG.id === _.get(state, "fieldGroupCache.id", null)
        ? state.fieldGroupCache
        : fG
    })
  },
  SET_FIELD_GROUP_CACHE: (state, fGroupCache) => {
    state.fieldGroupCache = fGroupCache
  },
  SET_FIELD_GROUPS_CACHE: (state, cache) => {
    state.fieldGroupsCache = cache
  },
  PUSH_FIELD_GROUPS_CACHE: (state, fieldGroup) => {
    state.fieldGroupsCache.push(fieldGroup)
  },
  UPDATE_FIELD_GROUP_CACHE: (state, params) => {
    state.fieldGroupCache.definition.label = params.label
    state.fieldGroupCache.definition.description = params.description
    state.fieldGroupCache.definition.store_multiple_responses =
      params.store_multiple_responses
  },

  // ---
  // Field Group Fields
  // ---

  SET_FIELD_GROUP_FIELD_CACHE: (state, cache) => {
    state.fieldGroupFieldCache = cache
  },
  SET_IS_ADDING_FIELD_GROUP_FIELD: (state, isAdding) => {
    state.isAddingFieldGroupField = isAdding
    if (!isAdding) {
      state.fieldGroupFieldCache = null
    }
  },
  ADD_FIELD_GROUP_FIELD: (state, fDef) => {
    state.fieldGroupCache.definition.definitions.push(fDef)
    state.fieldGroupFieldCache = null
    state.isAddingFieldGroupField = false
  },
  UPDATE_FIELD_GROUP_FIELD: (state, fDef) => {
    state.fieldGroupCache.definition.definitions = _.map(
      state.fieldGroupCache.definition.definitions,
      (d) => {
        return d.cuid === fDef.cuid ? fDef : d
      }
    )
  },
  REMOVE_FIELD_GROUP_FIELD: (state, fDef) => {
    state.fieldGroupCache.definition.definitions = _.filter(
      state.fieldGroupCache.definition.definitions,
      (f) => {
        return f.cuid !== fDef.cuid
      }
    )
  },
  SET_FIELD_GROUP_FIELDS: (state, fDefs) => {
    if (state.fieldGroupCache) {
      state.fieldGroupCache.definition.definitions = fDefs
    }
  },
  SET_FIELD_GROUP_FIELD_DATUM: (state, fieldDatum) => {
    if (state.fieldGroupCache) {
      state.fieldGroupCache.field_datum = fieldDatum
    }
  },

  // ---
  // Field Group Responses
  // ---

  SET_RESPONSES_CACHE: (state, params) => {
    state.fieldGroupCache = _.find(state.fieldGroupsCache, {
      id: params.field_group_id,
    })
    // Update
    if (params.responses) {
      state.responsesCache = params.responses
    }

    // Remove
    if (params.remove_cuid) {
      state.responsesCache = _.filter(
        parseResponses(state.fieldGroupCache),
        (r) => {
          return r.cuid !== params.remove_cuid
        }
      )
    }
    state.fieldGroupCache.field_datum.data.responses = state.responsesCache
  },
  SET_RESPONSE_CACHE: (state, params) => {
    state.fieldGroupCache = _.find(state.fieldGroupsCache, {
      id: params.field_group_id,
    })

    const datum = params.datum
    let resp =
      _.find(parseResponses(state.fieldGroupCache), {
        cuid: datum && datum.response_cuid,
      }) || state.responseCache

    if ((params && params.is_new_response) || !resp) {
      resp = genBaseResponse(state.fieldGroupCache)
      if (resp) {
        resp.is_new_response = params.is_new_response
      } else {
        return console.error(
          `Error: Invalid Response Cache State in group ${params.field_group_id}`
        )
      }
    }

    if (datum) {
      if (_.find(resp.fields, { cuid: datum.cuid })) {
        resp.fields = resp.fields.map((f) => {
          if (f.cuid === datum.cuid) {
            f.field_datum = datum
            f.field_datum.use_case = f.use_case
          }
          return f
        })
      }
    }

    resp.group_company_id = params.group_company_id
    resp.group_id = params.group_id

    state.responseCache = resp
  },
  RESET_RESPONSE_CACHE: (state, params) => {
    state.responseCache = null
  },
  DELETE_ATTACHMENTS_FOR_RESPONSE: (state, params) => {
    state.fieldGroupCache.field_datum.attachments = _.filter(
      state.fieldGroupCache.field_datum.attachments,
      (attachment) => {
        return attachment.response_cuid !== params.remove_cuid
      }
    )
  },
  SET_RESPONSE_CACHE_FOR_BULK_EDIT: (state, params) => {
    state.fieldGroupCache = _.find(state.fieldGroupsCache, {
      id: params.field_group_id,
    })

    const datum = params.datum
    let resp =
      _.find(parseResponses(state.fieldGroupCache), {
        cuid: datum && datum.response_cuid,
      }) || state.responseCache

    if ((params && params.is_new_response) || !resp) {
      resp = genBaseResponse(state.fieldGroupCache)
      resp.is_new_response = params.is_new_response
    }

    if (
      resp.is_new_response &&
      !resp.attachments &&
      _.get(state.fieldGroupCache, "field_datum.attachments", null)
    ) {
      // add existing files to response
      resp.attachments = _.get(
        state.fieldGroupCache,
        "field_datum.attachments",
        null
      )
    }

    if (datum) {
      // Keep running list of attachments
      if (_.get(datum, "files")) {
        datum.response_cuid = resp.cuid // test
        resp.attachments = parseFilesForBulkEdit(
          datum,
          _.get(resp, "attachments", null)
        )
      }
      // end response attachments

      if (_.find(resp.fields, { cuid: datum.cuid })) {
        resp.fields = resp.fields.map((f) => {
          if (f.cuid === datum.cuid) {
            f.field_datum = datum
            f.field_datum.use_case = f.use_case

            // Extra step to link atts to response
            if (_.get(datum, "files")) {
              f.field_datum.attachments = _.filter(resp.attachments, (a) => {
                return a.field_response_cuid === datum.cuid
              })
            }
            if (!_.isEmpty(f.field_datum.attachments)) {
              // New aditions to the array should be appended to this field datum
              const idArray = resp.attachments.reduce((arr, att) => {
                if (
                  att.field_response_cuid === f.cuid &&
                  att.response_cuid === resp.cuid
                ) {
                  arr.push(att.id)
                }
                return arr
              }, [])
              f.field_datum.data = {
                id_array: idArray,
              }
            }
            // end attach attachments
          }
          return f
        })
      }
    }
    resp.group_id = params.group_id
    state.responseCache = resp
  },
  SET_RESPONSE_ON_FIELD_GROUP_RESPONSES: (state) => {
    const respObj = {
      cuid: state.responseCache.cuid,
      created_at: state.responseCache.created_at,
      updated_at: state.responseCache.updated_at,
      response_data: _.map(state.responseCache.fields, (f) => {
        return { cuid: f.cuid, value: compileResponseDataValue(f) }
      }),
    }

    const isCreating = !_.get(state.fieldGroupCache, "field_datum", false) // TODO this was true

    let cache = isCreating
      ? []
      : state.fieldGroupCache.field_datum.data.responses

    const respAlreadyExists = _.find(cache, { cuid: respObj.cuid })
    if (respAlreadyExists) {
      cache = cache.map((r) => {
        return (r = r.cuid === respObj.cuid ? respObj : r)
      })
    } else {
      cache = _.flatten([respObj, cache])
    }
    if (isCreating) {
      Vue.set(state.fieldGroupCache, "field_datum", {
        use_case: FieldTypes.GROUP,
        field_id: state.fieldGroupCache.id,
        group_id: state.responseCache.group_id,
        group_company_id: state.responseCache.group_company_id,
        data: {
          responses: cache,
        },
        attachments: state.responseCache.attachments,
      })
    } else {
      state.fieldGroupCache.field_datum.data.responses = cache
      state.fieldGroupCache.field_datum.attachments =
        state.responseCache.attachments
    }
  },

  // ---
  // Helpers
  // ---

  RESET_CACHES: (state) => {
    state.fieldGroupsCache = []
    state.fieldGroupCache = null
    state.fieldGroupFieldCache = null
    state.responsesCache = null
    state.responseCache = null
    state.isAddingFieldGroupField = false
  },
  RELOAD_FIELD_GROUP_DATA_KEY: (state) => {
    state.reloadFieldGroupDataKey++
  },
}

// Create a representation of the attachment object
function parseFilesForBulkEdit(datum, oldFiles) {
  if (StaticHelper.blank(datum.files)) {
    return null
  }

  let highestId = !StaticHelper.blank(oldFiles)
    ? _.maxBy(oldFiles, (file) => {
        return file.id
      }).id + 1
    : 0

  const newFiles = []
  for (let i = 0; i < datum.files.length; i++) {
    newFiles.push({
      file_name: datum.files[i].name,
      file_type: datum.files[i].type,
      id: highestId++,
      model_type: "Attachment",
      file: datum.files[i],
      field_response_cuid: datum.cuid,
      response_cuid: datum.response_cuid,
    })
  }
  if (!StaticHelper.blank(oldFiles)) {
    return oldFiles.concat(newFiles)
  } else {
    return newFiles
  }
}

function compileResponseDataValue(f) {
  return StaticHelper.blank(_.get(f, "field_datum.data"))
    ? Definitions.buildBaseDatumDataById(f.definition.id)
    : f.field_datum.data
}
