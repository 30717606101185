import axios from "axios"
import Vue from "vue"
import StaticHelper from "@mixins/static-helpers"
import * as tus from "tus-js-client"

// Note: wherever TUS is used, set "urlStorage: null" to avoid
// caching file paths local storage. See config below.
// https://github.com/tus/tus-js-client/blob/main/docs/api.md

import {
  BaseState,
  BaseActions,
  BaseMutations,
  BaseGetters,
} from "@utils/mixins/store"

export const state = {
  ...BaseState,
  uploadingMessages: {
    // uid: message,
  },
  uploadStatus: {
    // uid: [inProgress, completed, failed]
  },
  parentModels: {
    // uid: model
  },
}

export const getters = {
  ...BaseGetters,
  endpoint: (state, getters, rootState, rootGetters) => {
    return `${rootGetters["env/serverBaseUrl"]}files`
  },
  uploadingMessages: (state) => (uid) => {
    return state.uploadingMessages[uid]
  },
  uploadStatus: (state) => (uid) => {
    return state.uploadStatus[uid]
  },
  parentModel: (state) => (uid) => {
    return state.parentModels[uid]
  },
}

export const actions = {
  ...BaseActions,
  upload: (
    { commit, dispatch, getters },
    { file, parentModel, fileNamespace, uid }
  ) => {
    commit("SET_PARENT_MODEL", { uid, parentModel })
    commit("SET_UPLOAD_STATUS", { uid, status: "inProgress" })
    commit("SET_LOADING_MESSAGE", {
      uid,
      message: `Uploading ${file.name}...`,
    })
    commit("CLEAR_ERRORS")
    var upload = new tus.Upload(file, {
      endpoint: getters.endpoint,
      retryDelays: [0, 3000, 5000, 10000, 20000],
      metadata: {
        filename: file.name,
        filetype: file.type,
        file_namespace: fileNamespace,
        model_type: parentModel.model_type,
        model_id: parentModel.id,
      },
      chunkSize: 5242880,
      urlStorage: null,
      storeFingerprintForResuming: false,
      onBeforeRequest: function (req) {
        // sends session data to backend for authentication
        var xhr = req.getUnderlyingObject()
        xhr.withCredentials = true
      },
      onError: function (error) {
        console.error(error)
        const errorBody =
          error && error.originalResponse && error.originalResponse.getBody()
        const json = errorBody ? StaticHelper.tryParseJson(errorBody) : null
        const errorMessage = json ? json["error"] : null
        dispatch(
          "app/setSnackbar",
          {
            text: `Uploading ${upload.file.name} failed. ${errorMessage || ""}`,
            icon: "warning",
            iconColor: "#DE7974",
          },
          { root: true }
        )
        commit("SET_UPLOAD_STATUS", { uid, status: "failed" })
      },
      onProgress: function (bytesUploaded, bytesTotal) {
        var percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2)
        if (percentage >= 100) {
          commit("SET_LOADING_MESSAGE", {
            uid,
            message: `Preparing your file...`,
          })
        } else {
          commit("SET_LOADING_MESSAGE", {
            uid,
            message: `Uploading ${upload.file.name}... ${percentage}% complete`,
          })
        }
      },
      onSuccess: function () {
        // Refetch parentModel, then commit
        const payload = {
          model: parentModel.model_type,
          id: parentModel.id,
        }
        dispatch("showPromise", payload)
          .then((model) => {
            commit("SET_PARENT_MODEL", { uid, parentModel: model })
            commit("SET_UPLOAD_STATUS", { uid, status: "complete" })

            if (_.get(model, "model_type") === "ContextView") {
              dispatch("contextViews/setFileUrl", model.file_url, {
                root: true,
              })
            }
          })
          .catch((e) => {
            dispatch(
              "app/setSnackbar",
              {
                text: "Something went wrong fetching your file...",
                icon: "warning",
                iconColor: "#DE7974",
              },
              { root: true }
            )
            commit("SET_UPLOAD_STATUS", { uid, status: "failed" })
          })
      },
      onShouldRetry: function (err, retryAttempt, options) {
        var status = err.originalResponse ? err.originalResponse.getStatus() : 0
        // Don't retry on known error edges that should never retry
        let edges = [403, 422, 500]
        if (edges.includes(status)) return false
        // For any other status code, we retry.
        return true
      },
    })
    upload.start()
  },
}

export const mutations = {
  ...BaseMutations,
  SET_LOADING_MESSAGE(state, { uid, message }) {
    Vue.set(state.uploadingMessages, uid, message)
  },
  SET_UPLOAD_STATUS(state, { uid, status }) {
    Vue.set(state.uploadStatus, uid, status)
  },
  SET_PARENT_MODEL(state, { uid, parentModel }) {
    Vue.set(state.parentModels, uid, parentModel)
  },
}
