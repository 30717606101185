import axios from "axios"
import SearchMixin from "@mixins/search"

import {
  BaseState,
  BaseActions,
  BaseMutations,
  BaseGetters,
} from "@utils/mixins/store"
import { _ } from "core-js"

export const state = {
  ...BaseState,
  networkGroups: [],
  messageGroups: [],
}

export const getters = {
  ...BaseGetters,
  networkGroups: (state) => {
    return state.networkGroups
  },
  messageGroups: (state) => {
    return state.messageGroups
  },
}

export const actions = {
  ...BaseActions,
  updateGroup: ({ commit, dispatch }, payload) => {
    commit("SET_LOADING", true)
    commit("CLEAR_ERRORS")
    return axios
      .put(`groups/${payload.id}`, payload.params)
      .then((response) => {
        commit("SET_MODEL", response.data["group"])
        dispatch(
          "signals/setSignal",
          {
            k: "updatedGroupObject",
            v: response.data["group"],
          },
          { root: true }
        )
      })
      .catch((e) => {
        commit("SET_ERRORS", e)
      })
      .finally(() => {
        commit("SET_LOADING", false)
        commit("INCREMENT_RELOAD_KEY")
      })
  },
  fetchNetworkGroups: ({ commit }, network) => {
    // build query obj
    const searchObj = SearchMixin.buildSearchObject({
      indices: ["groups"],
      includes: SearchMixin.groupIncludes,
      filters: [
        {
          attr: "network_id",
          type: "term",
          value: network.id,
          verb: "should",
        },
      ],
      page: 1,
      per_page: 10000,
      admin: true,
    })
    axios
      .get("search", { params: { q: searchObj } })
      .then((res) => {
        const groups = _.get(res, "data.results")
        commit("SET_NETWORK_GROUPS", groups)
      })
      .catch((error) => {
        console.error(error)
        commit("SET_NETWORK_GROUPS", [])
      })
  },
  fetchMessageGroups: ({ commit }, activeGroupId) => {
    return new Promise((resolve, reject) => {
      // Fetch Publishers
      axios
        .get(
          `snowflakes/publishers?reader_type=Group&reader_id=${activeGroupId}`
        )
        .then((publisherResp) => {
          const publishers = _.get(publisherResp, "data.networks")

          // Fetch Readers
          axios
            .get(
              `snowflakes/readers?publisher_type=Group&publisher_id=${activeGroupId}`
            )
            .then((readersResp) => {
              const messageGroups = buildMessageGroups(
                publishers,
                _.get(readersResp, "data.networks"),
                activeGroupId
              )
              commit("SET_MESSAGE_GROUPS", messageGroups)
              resolve(messageGroups)
            })
            .catch((error) => {
              console.error(error)
              commit("SET_MESSAGE_GROUPS", [])
              reject()
            })
        })
        .catch((error) => {
          console.error(error)
          commit("SET_MESSAGE_GROUPS", [])
          reject()
        })
    })
  },
  loadGroupMeta({ rootGetters }, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`group_meta/find?q={"find_by":{"key":${params.group_id}}}`)
        .then((response) => {
          resolve(response.data.group_meta)
        })
        .catch((e) => {
          reject(e)
        })
    })
  },
  updateGroupMeta({ rootGetters }, params) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["auth/kiteAdmin"]) {
        return reject()
      }
      axios
        .put("group_meta/" + params.group_meta_id, {
          id: params.group_meta_id,
          group_meta: {
            connection_settings: params.connection_settings,
          },
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((e) => {
          reject(e)
        })
    })
  },
  updateGroupMetaDefaultSourceWord({ dispatch, rootGetters }, params) {
    return new Promise((resolve, reject) => {
      // Nucla, Network, and Group admins can manage default source word.
      if (
        !rootGetters["auth/kiteAdmin"] &&
        !rootGetters["auth/isActiveAdmin"] &&
        !(params.key && params.value)
      ) {
        return reject()
      }

      const activeGroupMetaId = _.get(rootGetters["auth/activeGroupMeta"], "id")

      if (!activeGroupMetaId) {
        return reject()
      }

      axios
        .put("group_meta/" + activeGroupMetaId, {
          id: activeGroupMetaId,
          group_meta: {
            [params.key]: params.value,
          },
        })
        .then((resp) => {
          let groupMeta = _.get(resp, "data.group_meta")
          dispatch("auth/setActiveGroupMeta", groupMeta, { root: true })
          resolve(groupMeta)
        })
        .catch((e) => {
          reject(e)
        })
    })
  },
}

export const mutations = {
  ...BaseMutations,
  SET_NETWORK_GROUPS: (state, groups) => {
    state.networkGroups = groups
  },
  SET_MESSAGE_GROUPS: (state, groups) => {
    state.messageGroups = groups
  },
}

const buildMessageGroups = (publishers, readers, activeGroupId) => {
  if (_.isEmpty(publishers) && _.isEmpty(readers)) {
    return []
  }

  // Helper function to determine if the group can be messaged, where the group
  // has a contact email set, is publishable, and is not the activeGroup.
  function isMessageableGroup(g) {
    return (
      !!g.contact_email &&
      (g.readable || g.publishable) &&
      g.id !== activeGroupId
    )
  }

  const results = []

  // Build list of messageable network groups from publisher and reader collections.
  _.forEach(_.flattenDeep([publishers, readers]), (network) => {
    let netGroups = network.groups.map((v) => ({ ...v, selected: false }))
    const messageGroups = _.filter(netGroups, isMessageableGroup)
    const index = _.findIndex(results, {
      id: network.id,
    })

    // Check if the network already exists in the results cache. If so,
    // combine the two messageable group lists and return uniq results.
    if (index !== -1) {
      results[index].groups = _.uniqBy(
        _.flattenDeep([results[index].groups, messageGroups]),
        "id"
      )
    }
    // Otherwise, add set messegable groups and add the network to the results cache.
    else if (!_.isEmpty(messageGroups)) {
      network.groups = messageGroups
      results.push(network)
    }
  })

  return results
}
