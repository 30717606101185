import axios from "axios"
import { FieldExcludeAttr } from "@mixins/fields/types"
import StaticHelper from "@mixins/static-helpers"
import Vue from "vue"
import { labelOverride } from "@/src/utils/mixins/fields/overrides"

import {
  BaseState,
  BaseActions,
  BaseMutations,
  BaseGetters,
} from "@utils/mixins/store"

export const state = {
  ...BaseState,
  groupCompanyFieldData: null,
  groupCompanyTaxonomiesData: null,
  newProprietaryField: null,
  // Bulk Edit
  groupProprietaryFields: [],
  bulkFieldsBeingEdited: [],
  bulkEditFiles: [],
  // Caches
  cacheIsLoading: false,
  activeCache: null,
  groupUserCompaniesIndexCache: null,
  groupReportCompaniesIndexCache: null,
  groupCompaniesIndexCache: null,
  // Boolean for handling create state
  isCreating: false,
  isSaving: false,
  // Field interactions
  editingFieldWithDownstate: false,
}

export const mutations = {
  ...BaseMutations,
  SET_ALL: (state, params) => {
    state.all = _.forEach(params.fields, (f) => {
      f.label = labelOverride(f, params.group)
    })
  },
  SET_GROUP_PROPRIETARY_FIELDS: (state, newValue) => {
    state.groupProprietaryFields = newValue
  },
  SET_CACHE: (state, params) => {
    state[params.cache] = params.value
  },
  SET_CACHE_IS_LOADING: (state, newValue) => {
    state.cacheIsLoading = newValue
  },
  SET_GFC_FIELD: (state, params) => {
    const index = _.findIndex(state.all, (f) => {
      return f.id === params.id
    })

    if (index === -1) {
      console.error(
        "The field you're trying to modify does not exist in your GFC."
      )
      return
    }

    state.all[index][params.attribute] = params.newValue
  },
  SET_FIELD_DATUM_ON_CACHED_FIELD: (state, params) => {
    const field = _.find(state.bulkFieldsBeingEdited, {
      id: params.field.id,
    })
    Vue.set(field, "field_datum", {
      id: null,
      data: _.get(params, "datum.data", null),
      attachments: parseFilesForBulkEdit(
        _.get(params, "files", null),
        _.get(field, "field_datum.attachments", null)
      ),
      field_id: params.field.id,
      use_case: params.field.use_case,
      group_id: params.field.group_id,
    })
    const index = _.findIndex(state.bulkFieldsBeingEdited, (f) => {
      return f.id === field.id
    })
    state.bulkFieldsBeingEdited.splice(index, 1, field)
  },
  ADD_FIELD_TO_BULK_EDIT_CACHE: (state, field) => {
    const index = _.findIndex(state.bulkFieldsBeingEdited, (f) => {
      return f.id === field.id
    })
    if (index > -1) {
      state.bulkFieldsBeingEdited.splice(index, 1, field)
    } else {
      state.bulkFieldsBeingEdited.push(field)
    }
  },
  REMOVE_FIELD_FROM_BULK_EDIT_CACHE: (state, field) => {
    const index = _.findIndex(state.bulkFieldsBeingEdited, (f) => {
      return f.id === field.id
    })
    state.bulkFieldsBeingEdited.splice(index, 1)
  },
  UPDATE_GROUP_COMPANY_FIELD: (state, fieldDatum) => {
    const fid = _.get(fieldDatum, "field_id")
    if (StaticHelper.blank(fid)) {
      return
    }
    if (!StaticHelper.blank(state.groupCompanyFieldData)) {
      for (let i = 0; i < state.groupCompanyFieldData.length; i++) {
        if (state.groupCompanyFieldData[i].id === fid) {
          state.groupCompanyFieldData[i].field_datum = _.cloneDeep(fieldDatum)
        }
      }
    }
    if (!StaticHelper.blank(state.groupCompanyTaxonomiesData)) {
      for (let i = 0; i < state.groupCompanyTaxonomiesData.length; i++) {
        for (
          let j = 0;
          j < state.groupCompanyTaxonomiesData[i].fields.length;
          j++
        ) {
          if (state.groupCompanyTaxonomiesData[i].fields[j].id === fid) {
            state.groupCompanyTaxonomiesData[i].fields[j].field_datum =
              _.cloneDeep(fieldDatum)
          }
        }
      }
    }
  },
  SET_IS_CREATING: (state, boolean) => {
    state.isCreating = boolean
  },
  SET_IS_SAVING: (state, boolean) => {
    state.isSaving = boolean
  },
  SET_EDITING_FIELD_WITH_DOWNSTATE: (state, boolean) => {
    state.editingFieldWithDownstate = boolean
  },
}

export const getters = {
  ...BaseGetters,
  cacheIsLoading: (state) => {
    return state.cacheIsLoading
  },
  activeCache: (state) => {
    return state.activeCache
  },
  activeCacheNamespace: (state) => {
    return _.get(state.activeCache, "cache", null)
  },
  groupCompanyFieldData: (state) => {
    return state.groupCompanyFieldData
  },
  groupCompanyTaxonomiesData: (state) => {
    return state.groupCompanyTaxonomiesData
  },
  newProprietaryField: (state) => {
    return state.newProprietaryField
  },
  groupProprietaryFields: (state) => {
    return state.groupProprietaryFields
  },

  // ---
  // User Defaults
  // ---
  groupUserCompaniesIndexCache: (state) => {
    return state.groupUserCompaniesIndexCache
  },
  groupUserCompaniesIndexCacheFields: (state) => {
    return getCacheFields(state, state.groupUserCompaniesIndexCache)
  },
  groupCompaniesIndexCache: (state) => {
    return state.groupCompaniesIndexCache
  },
  groupCompaniesIndexCacheFields: (state) => {
    return getCacheFields(state, state.groupCompaniesIndexCache)
  },

  // ---
  // Report Defaults
  // ---
  groupReportCompaniesIndexCache: (state) => {
    return state.groupReportCompaniesIndexCache
  },
  groupReportCompaniesIndexCacheFields: (state) => {
    return getCacheFields(state, state.groupReportCompaniesIndexCache)
  },

  // ---
  // Bulk Edit
  // ---
  bulkFieldsBeingEdited: (state) => {
    return state.bulkFieldsBeingEdited
  },
  // ---
  // Track Creation state
  // ---
  isCreating: (state) => {
    return state.isCreating
  },
  isSaving: (state) => {
    return state.isSaving
  },
  syncingEdit: (state) => {
    return state.isCreating || state.isSaving
  },
  // ---
  // Field interactions
  // ---
  editingFieldWithDownstate: (state) => {
    return state.editingFieldWithDownstate
  },
}

export const actions = {
  ...BaseActions,
  newProprietaryField: ({ commit }, payload) => {
    commit("SET_CACHE", {
      cache: "newProprietaryField",
      value: payload,
    })
  },

  // --- Field
  fetchGftPool: ({ dispatch, rootGetters }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .get("snowflakes/fetch_group_field_tag_pool", {
          signal: params.signal,
          params: {
            field_id: params.fieldId,
            tag_field_cuid: params.tagFieldCuid,
            q: params.searchObject,
          },
        })
        .then((response) => {
          const data = _.get(response, "data", {})
          const results = _.get(response, "data.results", [])
          const currentValue =
            rootGetters["signals/getSignal"](params.gftPoolId) || []

          dispatch(
            "signals/setSignal",
            {
              k: params.gftPoolId,
              v: _.union(
                currentValue,
                results.map((x) => x.tag_readable)
              ),
            },
            { root: true }
          )
          resolve(data)
        })
        .catch((error) => {
          if (!_.get(params, "signal.reason.code") === 80085) {
            console.error(error)
          }
          reject(error)
        })
    })
  },

  createField: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      const setLoadingState = _.get(params, "callback", null) === "updateCache"

      if (setLoadingState) {
        commit("SET_LOADING", true)
      }

      axios
        .post("/fields", {
          group_id: params.group_id,
          taxonomy_id: params.taxonomy_id,
          use_case: params.use_case,
          definition: params.definition,
        })
        .then((response) => {
          const field = _.get(response, "data.field")
          dispatch(
            "signals/setSignal",
            {
              k: "newField",
              v: field,
            },
            { root: true }
          )
          dispatch("handleFieldCallback", {
            callback: params.callback,
            field: response.data.field,
            user_id: params.user_id,
            group_id: params.group_id,
            taxonomy_id: params.taxonomy_id,
          })
          dispatch(
            "amplitude/logEvent",
            {
              name: "Create Field",
              properties: {
                id: field.id,
                label: field.label,
                use_case: field.use_case,
                group_id: field.group_id,
                taxonomy_id: field.taxonomy_id,
                updated_at: field.updated_at,
                created_at: field.created_at,
              },
            },
            { root: true }
          )
          resolve()
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
        .finally(() => {
          if (setLoadingState) {
            commit("SET_LOADING", false)
          }
        })
    })
  },
  updateField: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("SET_LOADING", true)
      axios
        .put(`/fields/${params.field_id}`, {
          taxonomy_id: params.taxonomy_id,
          definition: params.definition,
        })
        .then((response) => {
          const field = _.get(response, "data.field")
          dispatch(
            "signals/setSignal",
            {
              k: "updatedField",
              v: field,
            },
            { root: true }
          )
          dispatch("handleFieldCallback", {
            callback: params.callback,
            field: response.data.field,
            user_id: params.user_id,
            group_id: params.group_id,
            taxonomy_id: params.taxonomy_id,
          })
          dispatch(
            "amplitude/logEvent",
            {
              name: "Update Field",
              properties: {
                id: field.id,
                label: field.label,
                use_case: field.use_case,
                group_id: field.group_id,
                taxonomy_id: field.taxonomy_id,
                updated_at: field.updated_at,
                created_at: field.created_at,
              },
            },
            { root: true }
          )
          dispatch("reloadFieldCaches")
          dispatch("app/reloadBaseSith", {}, { root: true })
          resolve()
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
        .finally(() => {
          commit("SET_LOADING", false)
        })
    })
  },
  getField: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("SET_LOADING", true)
      axios
        .get(`/fields/${params.field_id}`)
        .then((resp) => {
          resolve(_.get(resp, "data.field"))
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
        .finally(() => {
          commit("SET_LOADING", false)
        })
    })
  },
  handleFieldCallback: ({ dispatch, state, rootGetters }, params) => {
    const callback = params.callback
    const groupId = params.group_id
    const field = params.field

    if (callback && field && groupId) {
      if (callback.type === "createFieldDatum") {
        const groupCompanyId = _.get(
          state.activeCache,
          "group_company_id",
          null
        )

        if (groupCompanyId) {
          dispatch("createFieldDatum", {
            user_id: rootGetters["auth/activeUser"].id,
            field_id: field.id,
            use_case: field.use_case,
            group_company_id: groupCompanyId,
            group_id: groupId,
          })
        }
      }

      if (callback.type === "updateCache") {
        const existingFields = callback.existingFields
        let newFields = _.clone(existingFields || [])

        newFields = newFields.map((f) => f.hid)
        newFields.push(field.hid)

        if (_.isArray(newFields)) {
          const reportId = _.get(callback.activeCache, "report_id", null)
          const actionNamespace = StaticHelper.camelToPascalCase(
            callback.activeCache.cache
          )

          if (actionNamespace) {
            dispatch(`put${actionNamespace}`, {
              fields: newFields,
              user_id: params.user_id,
              group_id: groupId,
              report_id: reportId,
            })
          }
        }
      }

      if (callback.reloadFieldCaches) {
        dispatch("reloadFieldCaches")
      }
    }
  },
  // ---

  fetchGroupFields: (
    { state, commit, getters, rootState, rootGetters },
    payload
  ) => {
    commit("SET_LOADING", true)
    const params = typeof payload !== "object" ? { group_id: payload } : payload
    return new Promise((resolve, reject) => {
      commit("SET_ALL", { fields: [], group: rootGetters["auth/activeGroup"] })
      axios
        .get(
          `/cache?id=group_fields_caches&cache[group_id]=${params.group_id}&cache[permission_report_id]=${params.report_id}`
        )
        .then((response) => {
          const fields =
            response.data.value.fields === undefined
              ? []
              : JSON.parse(response.data.value.fields)
          commit("SET_ALL", {
            fields: fields,
            group: rootGetters["auth/activeGroup"],
          })
          resolve(fields)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
        .finally(() => {
          commit("SET_LOADING", false)
        })
    })
  },
  fetchGroupProprietaryFields: ({ commit }, payload) => {
    axios
      .get("snowflakes/group_proprietary_fields")
      .then((response) => {
        commit("SET_GROUP_PROPRIETARY_FIELDS", response.data.fields)
        return response.data.fields
      })
      .catch((error) => {
        console.error(error)
      })
  },
  // Note - As of March 2024, this is only used on the "Add Data" flow. We need to set
  // caches for the "parent group" context (ie. not the activeGroup) context being edited.
  setFieldsForBulkEdit: ({ commit }, fields) => {
    const namespaces = ["groupProprietaryFields", "bulkFieldsBeingEdited"]
    namespaces.forEach((namespace) => {
      commit("SET_CACHE", { cache: namespace, value: fields })
    })
  },
  resetFieldsForBulkEdit: ({ commit }) => {
    const namespaces = ["groupProprietaryFields", "bulkFieldsBeingEdited"]
    namespaces.forEach((namespace) => {
      commit("SET_CACHE", { cache: namespace, value: [] })
    })
  },

  // ---
  // Field Cache Helpers
  // ---
  resetCaches: ({ commit }, params) => {
    const cacheNamespaces = [
      "all",
      "newProprietaryFields",
      "groupCompanyFieldData",
      "groupUserCompaniesIndexCache",
      "groupReportCompaniesIndexCache",
      "groupCompaniesIndexCache",
    ]
    if (params.resetActiveCache) {
      cacheNamespaces.push("activeCache")
    }
    cacheNamespaces.forEach((cache) => {
      commit("SET_CACHE", { cache: cache, value: null })
    })
  },
  resetCache: ({ commit }, namespaces) => {
    namespaces.forEach((cache) => {
      commit("SET_CACHE", { cache: cache, value: null })
    })
  },
  reloadFieldCachesOnSettingsClose: ({ commit, dispatch }, params) => {
    dispatch("fetchGroupFields", params.group_id)
  },
  updateGFCField: ({ commit }, params) => {
    // params: { id: 123, attribute: "choices", newValue: ["one", "two", "three"] }
    if (!_.isEqual(_.keys(params), ["id", "attribute", "newValue"])) {
      console.error(
        "Invalid params were sent to the action 'fields/updateGFCField'."
      )
      return
    }
    commit("SET_GFC_FIELD", params)
  },

  // ---
  // Fields w/Datum
  // ---
  fetchGroupCompanyFieldData: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .get("/snowflakes/group_company_fields", {
          params: {
            group_id: params.group_id,
            company_id: params.company_id,
          },
        })
        .then((response) => {
          const fields = _.get(response, "data.fields")
          const taxonomies = _.get(response, "data.taxonomies")
          dispatch("fieldGroup/setFieldGroupsResponseCache", response, {
            root: true,
          })
          commit("SET_CACHE", {
            cache: "groupCompanyFieldData",
            value: fields,
          })
          commit("SET_CACHE", {
            cache: "groupCompanyTaxonomiesData",
            value: taxonomies,
          })
          resolve()
        })
        .catch((error) => {
          console.error(error)
          commit("SET_CACHE", {
            cache: "groupCompanyFieldData",
            value: null,
          })
          commit("SET_CACHE", {
            cache: "groupCompanyTaxonomiesData",
            value: null,
          })
          reject(error)
        })
    })
  },
  cacheFieldDatumInGCF: ({ commit }, params) => {
    commit("UPDATE_GROUP_COMPANY_FIELD", params.fieldDatum)
  },
  createFieldDatum: ({ dispatch, commit }, params) => {
    return new Promise((resolve, reject) => {
      commit("SET_LOADING", true)
      axios
        .post("/field_data", params)
        .then((response) => {
          const fieldDatum = _.get(response, "data.field_datum")
          commit("SET_CACHE", {
            cache: "newProprietaryField",
            value: fieldDatum,
          })
          dispatch("cacheFieldDatumInGCF", {
            fieldDatum: fieldDatum,
          })
          dispatch(
            "amplitude/logEvent",
            {
              name: "Set Field Value",
              properties: {
                field_id: fieldDatum.field_id,
                group_id: fieldDatum.group_id,
                field_datum_id: fieldDatum.id,
                group_company_id: fieldDatum.group_company_id,
              },
            },
            { root: true }
          )
          resolve(response.data.field_datum)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
        .finally(() => {
          commit("SET_LOADING", false)
        })
    })
  },
  fetchFieldDatum: ({ dispatch, commit }, params) => {
    if (_.isEmpty(params)) {
      return
    }

    return new Promise((resolve, reject) => {
      axios
        .get(`/field_data/${params.id}`, params)
        .then((response) => {
          commit("SET_CACHE", {
            cache: "newProprietaryField",
            value: response.data.field_datum,
          })
          dispatch("cacheFieldDatumInGCF", {
            fieldDatum: response.data.field_datum,
          })
          resolve(response.data.field_datum)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },
  updateFieldDatum: ({ dispatch, commit }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .put(`/field_data/${params.id}`, params)
        .then((response) => {
          const fieldDatum = _.get(response, "data.field_datum")
          commit("SET_CACHE", {
            cache: "newProprietaryField",
            value: fieldDatum,
          })
          dispatch("cacheFieldDatumInGCF", {
            fieldDatum: fieldDatum,
          })
          dispatch(
            "amplitude/logEvent",
            {
              name: "Set Field Value",
              properties: {
                field_id: fieldDatum.field_id,
                group_id: fieldDatum.group_id,
                field_datum_id: fieldDatum.id,
                group_company_id: fieldDatum.group_company_id,
              },
            },
            { root: true }
          )
          resolve(fieldDatum)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },
  deleteFieldDatum: ({ dispatch, commit }, params) => {
    return new Promise((resolve, reject) => {
      commit("SET_LOADING", true)
      axios
        .delete(`/field_data/${params.id}`)
        .then((resp) => {
          if (params.callback) {
            dispatch(params.callback, null, { root: true })
          }
          commit("SET_CACHE", {
            cache: "newProprietaryField",
            value: null,
          })
          dispatch(
            "amplitude/logEvent",
            {
              name: "Delete Field Value",
              properties: {
                field_datum_id: params.id,
              },
            },
            { root: true }
          )
          resolve()
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
        .finally(() => {
          commit("SET_LOADING", false)
        })
    })
  },
  findAndUpdateFieldDatum: ({ dispatch, commit }, params) => {
    // Find then update field_datum based on group_id, company_id, field_id combination.
    // params = { new_field_data: { group_id, company_id, field_id, use_case, data: { [selected] } }}
    return new Promise((resolve, reject) => {
      axios
        .post(`snowflakes/kanban_field_datum_update`, params)
        .then((response) => {
          commit("SET_CACHE", {
            cache: "newProprietaryField",
            value: response.data.field_datum,
          })
          dispatch("cacheFieldDatumInGCF", {
            fieldDatum: response.data.field_datum,
          })
          resolve(response.data.field_datum)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },

  // ---
  // User Defaults
  // ---
  fetchGroupUserCompaniesIndexCache: ({ commit }, params) => {
    // Determines columns (and order) on company tables.
    return new Promise((resolve, reject) => {
      axios
        .get(
          `/cache?id=group_user_companies_index_cache&cache[group_id]=${params.group_id}&cache[user_id]=${params.user_id}`
        )
        .then((response) => {
          commit("SET_CACHE", {
            cache: "groupUserCompaniesIndexCache",
            value: response.data,
          })

          const fields =
            response.data.value.fields === undefined
              ? []
              : response.data.value.fields

          commit("INCREMENT_RELOAD_KEY")
          resolve(fields)
          return fields
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },
  putGroupUserCompaniesIndexCache: ({ commit, dispatch }, params) => {
    // User default columns (and order) on company tables.
    return new Promise((resolve, reject) => {
      axios
        .put("cache?id=group_user_companies_index_cache", {
          cache: {
            group_id: params.group_id,
            user_id: params.user_id,
            value: JSON.stringify({
              fields: JSON.stringify(params.fields),
              filter: [],
            }),
          },
        })
        .then((response) => {
          commit("SET_CACHE", {
            cache: "groupUserCompaniesIndexCache",
            value: response.data,
          })
          let activeCache = {
            group_id: params.group_id,
            user_id: params.user_id,
            cache: "groupUserCompaniesIndexCache",
          }
          dispatch("setActiveCache", activeCache)

          const fields =
            response.data.value.fields === undefined
              ? []
              : response.data.value.fields

          resolve()
          return fields
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },
  fetchGroupCompaniesIndexCache: ({ commit }, params) => {
    // Determines columns (and order) on company tables.
    return new Promise((resolve, reject) => {
      axios
        .get(
          `/cache?id=group_companies_index_cache&cache[group_id]=${params.group_id}`
        )
        .then((response) => {
          commit("SET_CACHE", {
            cache: "groupCompaniesIndexCache",
            value: response.data,
          })

          const fields =
            response.data.value.fields === undefined
              ? []
              : response.data.value.fields

          commit("INCREMENT_RELOAD_KEY")
          resolve(fields)
          return fields
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },
  putGroupCompaniesIndexCache: ({ commit, dispatch }, params) => {
    // User default columns (and order) on company tables.
    return new Promise((resolve, reject) => {
      axios
        .put("cache?id=group_companies_index_cache", {
          cache: {
            group_id: params.group_id,
            value: JSON.stringify({
              fields: JSON.stringify(params.fields),
            }),
          },
        })
        .then((response) => {
          commit("SET_CACHE", {
            cache: "groupCompaniesIndexCache",
            value: response.data,
          })
          let activeCache = {
            group_id: params.group_id,
            user_id: params.user_id,
            cache: "groupCompaniesIndexCache",
          }
          dispatch("setActiveCache", activeCache)

          const fields =
            response.data.value.fields === undefined
              ? []
              : response.data.value.fields

          resolve()
          return fields
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },
  async fetchAllDefaultCompaniesCaches(
    { commit, dispatch, rootGetters },
    params = {}
  ) {
    commit("SET_CACHE_IS_LOADING", true)

    const activeGroupId = rootGetters["auth/activeGroup"].id
    const activeUserId = rootGetters["auth/activeUser"].id

    await dispatch("fetchGroupFields", activeGroupId)
    await dispatch("fetchGroupCompaniesIndexCache", {
      group_id: activeGroupId,
    })
    await dispatch("fetchGroupUserCompaniesIndexCache", {
      group_id: activeGroupId,
      user_id: activeUserId,
    })

    if (params.activeCache) {
      dispatch("setActiveCache", {
        group_id: activeGroupId,
        user_id: activeUserId,
        cache: params.activeCache,
      })
    }

    commit("SET_CACHE_IS_LOADING", false)
  },

  // ---
  // Report Defaults
  // ---
  fetchGroupReportCompaniesIndexCache: ({ commit }, params) => {
    // Determines columns (and order) on Report companies table.
    return new Promise((resolve, reject) => {
      axios
        .get(
          `/cache?id=group_report_companies_index_cache&cache[group_id]=${params.group_id}&cache[report_id]=${params.report_id}`
        )
        .then((response) => {
          commit("SET_CACHE", {
            cache: "groupReportCompaniesIndexCache",
            value: response.data,
          })

          const fields =
            response.data.value.fields === undefined
              ? []
              : response.data.value.fields

          resolve()
          return fields
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },
  putGroupReportCompaniesIndexCache: ({ commit }, params) => {
    // Report default columns (and order) on companies table.
    return new Promise((resolve, reject) => {
      axios
        .put("cache?id=group_report_companies_index_cache", {
          cache: {
            group_id: params.group_id,
            report_id: params.report_id,
            value: JSON.stringify({
              fields: JSON.stringify(params.fields),
              filter: [],
            }),
          },
        })
        .then((response) => {
          commit("SET_CACHE", {
            cache: "groupReportCompaniesIndexCache",
            value: response.data,
          })

          const fields =
            response.data.value.fields === undefined
              ? []
              : response.data.value.fields

          resolve()
          return fields
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },

  // ---
  // Active Cache Params
  // ---
  setActiveCache: ({ commit }, params) => {
    commit("SET_CACHE", {
      cache: "activeCache",
      value: params,
    })
  },

  reloadFieldCaches({ dispatch, state }) {
    if (_.get(state.activeCache, "cache", null)) {
      dispatch("fetchActiveCacheFields", {
        cache: _.get(state.activeCache, "cache", null),
        user_id: _.get(state.activeCache, "user_id", null),
        group_id: _.get(state.activeCache, "group_id", null),
        report_id: _.get(state.activeCache, "report_id", null),
        company_id: _.get(state.activeCache, "company_id", null),
        group_company_id: _.get(state.activeCache, "group_company_id", null),
      })
    }
  },
  fetchActiveCacheFields: ({ commit, dispatch }, params) => {
    const actionNamespace = StaticHelper.camelToPascalCase(params.cache)
    const setLoadingState = params.setLoadingState

    if (setLoadingState) {
      commit("SET_CACHE_IS_LOADING", true)
    }

    return new Promise((resolve, reject) => {
      if (!params.edit) {
        return dispatch("setActiveCache", params).then(() => {
          return dispatch("fetchGroupFields", params).then(() => {
            if (actionNamespace) {
              return dispatch(`fetch${actionNamespace}`, params)
                .then((fields) => {
                  resolve(fields)
                })
                .catch((e) => {
                  reject(e)
                })
                .finally(() => {
                  if (setLoadingState) {
                    commit("SET_CACHE_IS_LOADING", false)
                  }
                })
            } else {
              if (setLoadingState) {
                commit("SET_CACHE_IS_LOADING", false)
              }
              resolve()
            }
          })
        })
      } else {
        return dispatch("fetchGroupFields", params).then(() => {
          if (actionNamespace) {
            return dispatch(`fetch${actionNamespace}`, params)
              .then((fields) => {
                resolve(fields)
              })
              .catch((e) => {
                reject(e)
              })
              .finally(() => {
                if (setLoadingState) {
                  commit("SET_CACHE_IS_LOADING", false)
                }
              })
          } else {
            if (setLoadingState) {
              commit("SET_CACHE_IS_LOADING", false)
            }
            resolve()
          }
        })
      }
    })
  },

  // ---
  // Field Bulk Edit Helpers
  // ---
  cacheDatumForBulkEdit: ({ commit, state }, params) => {
    commit("SET_FIELD_DATUM_ON_CACHED_FIELD", params)
  },
  addFieldToBulkEditCache: ({ commit }, field) => {
    commit("ADD_FIELD_TO_BULK_EDIT_CACHE", field)
  },
  removeFieldFromBulkEditCache: ({ commit }, field) => {
    commit("REMOVE_FIELD_FROM_BULK_EDIT_CACHE", field)
  },
  // ---
  // Field creation state actions
  // ---
  setIsCreating: ({ commit }, boolean) => {
    commit("SET_IS_CREATING", boolean)
  },
  setIsSaving: ({ commit }, boolean) => {
    commit("SET_IS_SAVING", boolean)
  },

  // ---
  // Helpers
  // ---
  setLoadingState: ({ commit }, loadingState) => {
    commit("SET_LOADING", loadingState)
  },

  // ---
  // Field interactions
  // ---
  setEditingFieldWithDownstate: ({ commit }, editingFieldState) => {
    commit("SET_EDITING_FIELD_WITH_DOWNSTATE", editingFieldState)
  },
}

// ---
// Private Helpers
// ---
function getCacheFields(state, cache) {
  if (_.isEmpty(state.all)) return []
  const fields = JSON.parse(_.get(cache, "value.fields", "[]"))
  if (_.isArray(fields) && !_.isEmpty(fields)) {
    return _.compact(
      fields.map((hid) => {
        return state.all.find((f) => {
          // If a group shares a field, the GRCIC will return the raw hid (without the group number prepended on it)
          // ex: "12345678"
          // if a group shares a field, then the group it was shared to shares that field back in a report
          // the GRCIC will return the field prepended with the group id
          // ex: 2_12345678
          // We need to get the raw hid in order to match it against any hids in the GFC
          const rawHid = _.get(hid.split("_"), 1, hid)
          return (
            filterByExcludeAttrs(f) &&
            (rawHid === f.hid || new RegExp(`(.*?)_${rawHid}`).test(f.hid))
          )
        })
      })
    )
  } else {
    return []
  }
}

function filterByExcludeAttrs(field) {
  return !FieldExcludeAttr.includes(`${field.attribute}`)
}

function parseFilesForBulkEdit(newFiles, oldFiles) {
  if (StaticHelper.blank(newFiles)) {
    return null
  }

  newFiles = _.map(newFiles, (file, index) => {
    return {
      file_name: file.name,
      file_type: file.type,
      id: index,
      model_type: "Attachment",
      file: file,
    }
  })
  if (!StaticHelper.blank(oldFiles)) {
    return oldFiles.concat(newFiles)
  } else {
    return newFiles
  }
}
