import axios from "axios"

export const state = {
  all: null,
}

export const mutations = {}

export const getters = {}

export const actions = {
  // eslint-disable-next-line no-unused-vars
  postFieldData: ({ commit, rootGetters }, payload) => {
    axios
      .post("/field_data/", {
        user_id: rootGetters["auth/activeUser"].id,
        group_id: payload.groupId,
        group_company_id: payload.groupCompanyId,
        field_id: payload.fieldId,
        data: payload.data,
      })
      .catch((error) => {
        console.error(error)
      })
  },
  // eslint-disable-next-line no-unused-vars
  putFieldData: ({ commit }, payload) => {
    axios
      .put("/field_data/" + payload.id, {
        user_id: rootGetters["auth/activeUser"].id,
        group_id: payload.groupId,
        group_company_id: payload.groupCompanyId,
        field_id: payload.fieldId,
        data: payload.data,
      })
      .catch((error) => {
        console.error(error)
      })
  },
}
