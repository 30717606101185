import axios from "axios"
import {
  BaseState,
  BaseActions,
  BaseMutations,
  BaseGetters,
} from "@utils/mixins/store"
import { ConnectionUseCase } from "@mixins/connections"
import SearchMixin from "@mixins/search"

export const state = {
  ...BaseState,
  isLoading: false,
  showEmptyCards: false,
  companyInGroup: false,
  fieldCardTemplate: null,
  allFieldCards: null,
  isEditing: false,
  reloadContextualDataCard: false,
}

export const getters = {
  ...BaseGetters,
  isLoading: (state) => {
    return state.isLoading
  },
  reloadContextualDataCard: (state) => {
    return state.reloadContextualDataCard
  },
  fieldCardTemplate: (state) => {
    return state.fieldCardTemplate
  },
  allFieldCards: (state) => {
    return state.allFieldCards
  },
  companyInGroup: (state) => {
    return state.companyInGroup
  },
  showEmptyCards: (state) => {
    return state.showEmptyCards
  },
  isEditing: (state) => {
    return state.isEditing
  },
  displayCards: (state, getters, rootState, rootGetters) => {
    return rootGetters["auth/shareToken"]
      ? _.get(getters, "providerCards", [])
      : _.get(getters, "fieldCardTemplate.template.cards", [])
  },
  availableIntegrations: (state, getters, rootState, rootGetters) => {
    if (!rootGetters["auth/activeIntegrations"]) {
      return []
    }

    const activeIntegrations = rootGetters["auth/activeIntegrations"]
    const available = []

    if (!activeIntegrations.clearbit) {
      available.push({
        id: "clearbit",
        name: "Clearbit",
        logo: "https://ryse-static.s3.amazonaws.com/logos/clearbit-icon.svg",
        branding_color: "#4db1fd",
        email: "support@clearbit.com",
        text: "Enrich existing companies with data such as: sector, amount raised, estimated annual revenue, and more. Browse a feed of new and historical activity.",
        link: "https://www.nucla.com/connections/clearbit",
        use_case: ConnectionUseCase.GROUP_INTEGRATIONS,
      })
    }

    if (!activeIntegrations.crunchbase) {
      available.push({
        id: "crunchbase",
        name: "Crunchbase",
        logo: "https://ryse-static.s3.amazonaws.com/logos/crunchbase-icon.svg",
        branding_color: "#146aff",
        email: "support@crunchbase.com",
        text: "Enrich existing companies with data such as: investors, categories, funding rounds, and more. Browse a feed of new and historical activity.",
        link: "https://www.nucla.com/connections/crunchbase",
        use_case: ConnectionUseCase.GROUP_INTEGRATIONS,
      })
    }

    if (!activeIntegrations.owler) {
      available.push({
        id: "owler",
        name: "Owler",
        logo: "https://ryse-static.s3.amazonaws.com/logos/owler-logo.png",
        branding_color: "#0ba2a2",
        email: "support@owler.com",
        text: "Enrich existing companies with data such as: industries, acquisitions, funding, and more. Browse a feed of new and historical activity.",
        link: "https://www.nucla.com/connections/owler",
        use_case: ConnectionUseCase.GROUP_INTEGRATIONS,
      })
    }

    // if (!activeIntegrations.similarweb) {
    //   available.push({
    //     id: "similarweb",
    //     name: "SimilarWeb",
    //     logo: "https://ryse-static.s3.amazonaws.com/logos/similar-web-logo.png",
    //     branding_color: "#202c55",
    //     email: "support@similarweb.com",
    //     text: "Enrich existing companies with website traffic intelligence such as: similar sites, geographic visit distribution, global traffic rank, and more. Browse a feed of new and historical activity.",
    //     link: "https://www.nucla.com/connections/similar-web",
    //     use_case: ConnectionUseCase.GROUP_INTEGRATIONS,
    //   })
    // }

    if (!activeIntegrations.pitchbook) {
      available.push({
        id: "pitchbook",
        name: "PitchBook",
        logo: "https://ryse-static.s3.amazonaws.com/logos/pitchbook-icon.svg",
        branding_color: "#4e6b8f",
        email: "support@pitchbook.com",
        text: "Connect your existing PitchBook account to enrich your companies with the premier provider of private market data.",
        link: "https://www.nucla.com/connections/pitchbook",
        use_case: ConnectionUseCase.GROUP_INTEGRATIONS,
      })
    }

    if (!activeIntegrations.salesforce) {
      available.push({
        id: "salesforce",
        name: "Salesforce",
        logo: "https://ryse-static.s3.amazonaws.com/logos/salesforce.png",
        branding_color: "#17A0DB",
        email: null,
        text: "Import and sync fields and companies from your Salesforce account.",
        link: "https://www.nucla.com/connections/salesforce",
        use_case: ConnectionUseCase.GROUP_TRAY_INTEGRATIONS,
      })
    }

    if (!activeIntegrations.microsoft_dynamics_365) {
      available.push({
        id: "microsoft_dynamics_365",
        name: "Microsoft Dynamics 365",
        logo: "https://ryse-static.s3.amazonaws.com/logos/microsoft-dynamics-365.png",
        branding_color: "#4054BB",
        email: null,
        text: "Import and sync fields and companies from your Microsoft Dynamics 365 account.",
        link: "https://www.nucla.com/connections/microsoft-dynamics-365",
        use_case: ConnectionUseCase.GROUP_TRAY_INTEGRATIONS,
      })
    }

    if (!activeIntegrations.typeform) {
      available.push({
        id: "typeform",
        name: "Typeform",
        logo: "https://ryse-static.s3.amazonaws.com/logos/typeform-icon.svg",
        branding_color: "#f5f1ea",
        email: null,
        text: "Automatically import company responses from your existing forms.",
        link: "https://www.nucla.com/connections/typeform",
        use_case: ConnectionUseCase.GROUP_INTEGRATIONS,
      })
    }

    return available
  },
  // groupCards: (state, getters, rootState, rootGetters) => {
  //   const activeGroup = rootGetters["auth/activeGroup"]
  //   const activeNetwork = rootGetters["auth/activeNetwork"]
  //   const brandingColor = activeGroup.brand_color || activeNetwork.brand_color
  //   const brandingLogo = activeGroup.branding_logo || activeNetwork.logo_url
  //
  //   const groupCards = _.map(
  //     rootGetters["fields/groupCompanyTaxonomiesData"],
  //     (taxonomy) => {
  //       return {
  //         use_case: ConnectionUseCase.GROUP_ACTIVE,
  //         taxonomy_id: taxonomy.id,
  //         name: taxonomy.label,
  //         logo: brandingLogo,
  //         branding_color: brandingColor,
  //         ...taxonomy,
  //       }
  //     }
  //   )

  // const emptyCards = _.filter(displayCards, { is_empty: true })

  // if (
  //   !getters.isLoading &&
  //   !getters.searchTerm &&
  //   !getters.showEmptyCards &&
  //   !_.isEmpty(emptyCards) &&
  //   (!_.isEqual(emptyCards.length, 1) || !getters.companyInGroup)
  // ) {
  //   displayCards.push({
  //     id: "is_empty",
  //     name: `Empty Connection Cards (${emptyCards.length})`,
  //     card_stack: true,
  //   })
  // }
  providerCards: () => {
    // Hardcoded provider data options for shareToken public usage.
    return [
      {
        provider: "clb",
        use_case: ConnectionUseCase.GROUP_INTEGRATIONS,
        name: "Clearbit",
        logo: "https://ryse-static.s3.amazonaws.com/logos/clearbit-icon.svg",
        branding_color: "#4db1fd",
        email: "support@clearbit.com",
      },
      {
        provider: "cb",
        use_case: ConnectionUseCase.GROUP_INTEGRATIONS,
        name: "Crunchbase",
        logo: "https://ryse-static.s3.amazonaws.com/logos/crunchbase-icon.svg",
        branding_color: "#146aff",
        email: "support@crunchbase.com",
      },
      {
        provider: "owl",
        use_case: ConnectionUseCase.GROUP_INTEGRATIONS,
        name: "Owler",
        logo: "https://ryse-static.s3.amazonaws.com/logos/owler-logo.png",
        branding_color: "#0ba2a2",
        email: "support@owler.com",
      },
      // {
      //   provider: "sw",
      //   use_case: ConnectionUseCase.GROUP_INTEGRATIONS,
      //   name: "SimilarWeb",
      //   logo: "https://ryse-static.s3.amazonaws.com/logos/similar-web-logo.png",
      //   branding_color: "#202c55",
      //   email: "support@similarweb.com",
      // },
    ]
  },

  getFieldCardQueryParams: (state) => {
    return state.fieldCardQueryParams
  },
}

export const actions = {
  ...BaseActions,
  set: ({ commit }, obj) => {
    commit("SET_CACHE", {
      cache: obj.k,
      value: obj.v,
    })
  },
  resetFieldCardTemplate({ commit }) {
    commit("SET_CACHE", {
      cache: "fieldCardTemplate",
      value: null,
    })
  },
  initializeFieldCards({ dispatch, commit, getters, rootGetters }, params) {
    // Params - group_id, company_id, company_in_group
    new Promise((resolve) => {
      commit("SET_LOADING", true)

      // Public Links can only view provider data
      if (!rootGetters["auth/shareToken"]) {
        // Templates
        if (_.isEmpty(getters.fieldCardTemplate)) {
          dispatch("loadFieldCardTemplate", params)
        } else {
          commit("SET_CACHE", {
            cache: "showEmptyCards",
            value: _.get(getters, "fieldCardTemplate.template.show_empty"),
          })
        }
        commit("SET_CACHE", {
          cache: "companyInGroup",
          value: params.company_in_group,
        })
      }

      commit("SET_LOADING", false)
      resolve()
    })
  },
  loadAllFieldCards({ commit, dispatch }, params) {
    // AllFieldCards Snowflake API
    // https://stage.api.nucla.com/api/v1/snowflakes/all_field_cards?group_id=1
    new Promise((resolve, reject) => {
      axios
        .get("snowflakes/all_field_cards", {
          params: { group_id: params.group_id },
        })
        .then((response) => {
          commit("SET_CACHE", {
            cache: "allFieldCards",
            value: _.get(response, "data.cards"),
          })
          resolve()
        })
        .catch((error) => {
          console.error(error)
          commit("SET_CACHE", {
            cache: "allFieldCards",
            value: null,
          })
          reject(error)
        })
    })
  },
  loadFieldCardTemplate({ commit, dispatch, getters }, params) {
    // if editing is open
    if (getters.isEditing) {
      dispatch("toggleEditState")
    }
    // FieldCardTemplate Search API
    // Note - we're seeding every group with one default template that can only be updated.
    // This will be expanded in the future to allow groups and users to manage many
    // different field card templates with a default loaded from the session user cache.
    new Promise((resolve, reject) => {
      const searchObj = {
        params: {
          q: SearchMixin.buildSearchObject({
            indices: ["field_card_templates"],
            includes: SearchMixin.fieldCardTemplateIncludes,
            baseSort: { attr: "updated_at", order: "desc" },
            customAttrs: [
              {
                pattern: "field_card_template_relational_data",
              },
            ],
            filters: [
              {
                attr: "group_id",
                value: params.group_id,
                type: "term",
              },
            ],
          }),
        },
      }

      axios
        .get("search", searchObj)
        .then((response) => {
          const groupDefaultTemplate = _.last(_.get(response, "data.results"))
          if (!_.isEmpty(groupDefaultTemplate)) {
            commit("SET_CACHE", {
              cache: "showEmptyCards",
              value: _.get(groupDefaultTemplate, "template.show_empty"),
            })
            commit("SET_CACHE", {
              cache: "fieldCardTemplate",
              value: groupDefaultTemplate,
            })
            dispatch("companyProfile/incrementFieldCardGridReloadKey", null, {
              root: true,
            })
          }
          resolve()
        })
        .catch((error) => {
          console.error(error)
          commit("SET_CACHE", {
            cache: "fieldCardTemplate",
            value: null,
          })
          reject(error)
        })
    })
  },
  showEmptyCards({ commit, getters }) {
    commit("SET_CACHE", {
      cache: "showEmptyCards",
      value: !getters.showEmptyCards,
    })
  },

  // Template Management
  saveGroupDefaultTemplate({ commit, getters, dispatch }, params) {
    const templateId = _.get(getters, "fieldCardTemplate.id")
    const strippedTemplateCardObjects = _.map(params.cards, (c) => {
      let obj = {
        use_case: c.use_case,
      }

      // Group Cards
      if (_.isEqual(c.use_case, ConnectionUseCase.GROUP_ACTIVE)) {
        _.set(obj, "taxonomy_id", c.taxonomy_id || null)
      }

      // Shared Cards
      else if (_.isEqual(c.use_case, ConnectionUseCase.GROUP_SUBSCRIPTIONS)) {
        _.set(obj, "group_id", c.group_id)
      }

      // Typeform Cards
      else if (
        _.isEqual(c.use_case, ConnectionUseCase.GROUP_INTEGRATIONS) &&
        !!c.integration_form_id
      ) {
        _.set(obj, "integration_form_id", c.integration_form_id)
      }

      // Provider Cards
      else if (
        _.isEqual(c.use_case, ConnectionUseCase.GROUP_INTEGRATIONS) &&
        !!c.provider
      ) {
        _.set(obj, "provider", c.provider)
      }

      // Tray Integrations
      else if (
        _.isEqual(c.use_case, ConnectionUseCase.GROUP_TRAY_INTEGRATIONS) &&
        !!c.solution_instance_id
      ) {
        _.set(obj, "solution_instance_id", c.solution_instance_id)
        _.set(obj, "provider", c.provider)
      }

      // Report Card
      else if (_.isEqual(c.use_case, ConnectionUseCase.GROUP_REPORT)) {
        _.set(obj, "report_id", c.report_id)
      }

      return obj
    })

    commit("SET_LOADING", true)
    axios
      .put(`field_card_templates/${templateId}`, {
        id: templateId,
        field_card_template: {
          group_id: params.group_id,
          template: {
            cards: strippedTemplateCardObjects,
            show_empty: params.show_empty || false,
          },
        },
      })
      .then((response) => {
        dispatch("loadFieldCardTemplate", params)
      })
      .catch((e) => {
        commit("SET_CACHE", {
          cache: "fieldCardTemplate",
          value: null,
        })
      })
      .finally(() => {
        commit("SET_LOADING", false)
      })
  },
  toggleEditState({ commit }) {
    commit("SET_EDITING")
  },
  reloadContextualDataCard({ commit }) {
    commit("RELOAD_CONTEXTUAL_DATA_CARD")
  },
  setFieldCardQueryParams({ commit }, params) {
    commit("SET_FIELD_CARD_QUERY_PARAMS", params)
  },
}

export const mutations = {
  ...BaseMutations,
  SET_CACHE(state, payload) {
    state[payload.cache] = payload.value
  },
  SET_LOADING(state, isLoading) {
    state.isLoading = isLoading
  },
  SET_EDITING(state) {
    state.isEditing = !state.isEditing
  },
  RELOAD_CONTEXTUAL_DATA_CARD(state) {
    state.reloadContextualDataCard = !state.reloadContextualDataCard
  },
  SET_FIELD_CARD_QUERY_PARAMS(state, payload) {
    state.fieldCardQueryParams[payload.key] = payload.value
  },
}
