import axios from "axios"
import {
  BaseState,
  BaseActions,
  BaseMutations,
  BaseGetters,
} from "@utils/mixins/store"
import { ConnectionUseCase } from "@mixins/connections"
import SearchMixin from "@mixins/search"

export const state = {
  ...BaseState,
  isLoading: false,
  isEditing: true,
  filterCardTemplate: null,
}

export const getters = {
  ...BaseGetters,
  isLoading: (state) => {
    return state.isLoading
  },
  isEditing: (state) => {
    return state.isEditing
  },
  filterCardTemplate: (state) => {
    return state.filterCardTemplate
  },
  filterCards: (getters) => {
    return _.get(getters, "filterCardTemplate.template.cards", [])
  },
}

export const actions = {
  ...BaseActions,
  set: ({ commit }, obj) => {
    commit("SET_CACHE", {
      cache: obj.k,
      value: obj.v,
    })
  },
  loadFilterCardTemplate({ commit, dispatch, getters }, params) {
    return new Promise((resolve, reject) => {
      const searchObj = {
        params: {
          q: SearchMixin.buildSearchObject({
            indices: ["field_card_templates"],
            includes: SearchMixin.fieldCardTemplateIncludes,
            baseSort: { attr: "updated_at", order: "desc" },
            customAttrs: [
              {
                pattern: "all_template_card_data",
              },
            ],
            filters: [
              {
                attr: "group_id",
                value: params.group_id,
                type: "term",
              },
            ],
          }),
        },
      }
      axios
        .get("search", searchObj)
        .then((response) => {
          const filterCardTemplate = _.last(_.get(response, "data.results"))
          commit("SET_CACHE", {
            cache: "filterCardTemplate",
            value: filterCardTemplate,
          })
          resolve()
        })
        .catch((error) => {
          console.error(error)
          commit("SET_CACHE", {
            cache: "filterCardTemplate",
            value: null,
          })
          reject(error)
        })
    })
  },

  setEditState({ commit }, isEditing) {
    commit("SET_EDITING", isEditing)
  },
  toggleEditState({ commit }) {
    commit("TOGGLE_EDITING")
  },
}

export const mutations = {
  ...BaseMutations,
  SET_CACHE(state, payload) {
    state[payload.cache] = payload.value
  },
  SET_LOADING(state, isLoading) {
    state.isLoading = isLoading
  },
  SET_EDITING(state, isEditing) {
    state.isEditing = isEditing
  },
  TOGGLE_EDITING(state) {
    state.isEditing = !state.isEditing
  },
}
